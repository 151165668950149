<template>
    <div>
        <h2 style="text-align: center;margin-top: 25px;">{{ $t('companyprofile.setInfo') }}</h2>
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
        
        <a-form-item :label="$t('companyprofile.item_0')">
          <a-upload
            name="file"
            :multiple="true"
            :headers="headers"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :default-file-list="defaultFileList3"
            :action="upload_url"
            :before-upload="beforeUpload2"
            @change="handleChange3"
          >
          <!-- https://labs.datahunt.app/ -->
            <img v-if="logo" :src="get_logo()" alt="logo" style="width: 100px;"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                {{$t('companyprofile.btn_upload')}} 
                (300px*300px)
              </div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item :label="$t('companyprofile.item_1')">
          <a-input
            v-decorator="['company_name', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>
        <a-form-item :label="$t('companyprofile.item_2')">
          <a-input
            v-decorator="['contact_name', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>
        <a-form-item :label="$t('companyprofile.item_3')">
          <a-input
            v-decorator="['contact_no', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
            </a-form-item>
          <a-form-item :label="$t('companyprofile.item_4')">
          <a-input
            v-decorator="['company_address', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>
        <a-form-item :label="$t('companyprofile.item_5')">
          <a-input
            v-decorator="['email', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>
      
        <a-form-item :label="$t('companyprofile.item_6')">
          <a-input
            v-decorator="['website', { rules: [{ required: true, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="Target">
          <a-input
            v-decorator="['target', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="BestBuy">
          <a-input
            v-decorator="['best_buy', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="Instagram">
          <a-input
            v-decorator="['instagram', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="Facebook">
          <a-input
            v-decorator="['facebook', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>

        <a-form-item label="YouTube">
          <a-input
            v-decorator="['you_tube', { rules: [{ required: false, message: $t('message.info')  }] }]"
          />
        </a-form-item>


        <a-form-item :label="$t('companyprofile.item_7')">
          <!-- <a-input
            v-decorator="['about', { rules: [{ required: true, message: $t('message.info')  }] }]"
          /> -->
          <a-textarea
              placeholder=""
              v-decorator="[
                  'about',
                  { rules: [{ required: true, message: $t('message.info') },{max: 300, message: $t('message.infoMax300'), trigger: 'blur' },] },
              ]"
          />
        </a-form-item>
        <a-form-item :label="$t('companyprofile.item_8')">
          <a-upload
            name="file"
            :multiple="true"
            :headers="headers"
            :default-file-list="defaultFileList"
            :action="upload_url"
            @change="handleChange"
          >
            <a-button v-if="isShow"> <a-icon type="upload" /> {{$t('companyprofile.btn_upload')}} </a-button>
            <span>{{path}}</span>
          </a-upload>
        </a-form-item>

        <a-form-item :label="$t('companyprofile.item_9')">
          <a-upload
            name="file"
            :multiple="true"
            :headers="headers"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :default-file-list="fileList"
            :action="upload_url"
            :before-upload="beforeUpload"
            @change="handleChange2"
          >
            <img v-if="file_cover" :src="get_file_cover()" alt="cover" style="width: 100px;"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                {{$t('companyprofile.btn_upload')}}
                (150px*85px)
              </div>
            </div>
          </a-upload>

        </a-form-item>
        
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" class='ant-btn-danger' html-type="submit">
            {{$t('companyprofile.btn_submit')}}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
</template>

<script>

import {editProfile,getProfile} from '@/network/common'

export default {
    name: 'NodeprojectRegister',
    components: { },
    directives: { },
    data() {
        return {
            url:'https://labs.datahunt.app',
            upload_url:'https://labs.datahunt.app/index.php/upload',
            // url:'http://makeradmin.net',
            // upload_url:'http://makeradmin.net/index.php/upload',
            spinning: true,
            delayTime: 500,
            profileData:{},
            formLayout: 'horizontal',
            isShow:true,
            isShow2:true,
            isShow3:true,
            defaultFileList:[],
            defaultFileList2:[],
            defaultFileList3:[],
            file_cover:'',
            form: this.$form.createForm(this, { name: 'coordinated' }),
            headers: {
              'X-Requested-With': null,
            },
            logo:'',
            path:'',
            img_path:'',
            loading:false,
            fileList:[],
        };
    },
    created() {
        getProfile()
        .then(res=>{
          this.spinning = false;
          // console.log(res)
          if(res.header.code==200){
            // this.form=res.body.profile
            // this.form.email=res.body.profile.email
            this.profileData=res.body.profile
            this.form.setFieldsValue({
              email:res.body.profile.email,
              website:res.body.profile.website,
              company_name:res.body.profile.company_name,
              company_address:res.body.profile.company_address,
              contact_no:res.body.profile.contact_no,
              contact_name:res.body.profile.contact_name,
              about:res.body.profile.about,
              
              target:res.body.profile.target,
              best_buy:res.body.profile.best_buy,
              instagram:res.body.profile.instagram,
              facebook:res.body.profile.facebook,
              you_tube:res.body.profile.you_tube,
            });
            let file_doc = res.body.profile.file_doc;
            let logo = res.body.profile.logo;
            // console.log(file_doc);
            if(file_doc){
              this.defaultFileList = [{
                uid: '1',
                name: file_doc,
                status: 'done',
                response: 'success',
                url: this.url+file_doc,
              }];
              this.path = file_doc;
              // this.isShow = false;
            }else{
              this.isShow = true;
            }
            if(logo){
              this.defaultFileList3 = [{
                uid: '1',
                name: logo,
                status: 'done',
                response: 'success',
                url: this.url+logo,
              }];
              this.logo = logo;
            }else{
              this.isShow = true;
            }
            this.fileList =[{
              uid: '2',
              name: res.body.profile.file_cover,
              status: 'done',
              response: 'success',
              url: this.url+res.body.profile.file_cover,
            }];
            this.file_cover = res.body.profile.file_cover;
          }
        })
    },
    mounted() {
        
    },
    methods: {
        get_file_cover(){
            // return 'https://labs.datahunt.app/'+this.file_cover;
            return this.url+this.file_cover;
        },
        get_logo(){
            // return 'https://labs.datahunt.app/'+this.logo;
            return this.url+this.logo;
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.spinning = true;
                    values.brand_name=this.profileData.brand_name;
                    values.logo=this.url+this.logo;
                    values.file_doc = this.path;
                    values.file_cover = this.file_cover;
                    editProfile(values)
                    .then(res=>{
                        this.spinning = false;
                        if(res.header.code==200){
                            // this.$message.success('save success!');
                            // return ;
                            this.$router.push('/products2/browse');
                            return;
                        }
                        this.$message.error(res.header.msg);
                    })
                }
            });
        },
        handleChange(info){
            console.log(info);
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'removed') {
                this.isShow = true;
                this.path = '';
            }else if (info.file.status === 'done') {
                if(info.file.response.code == 0){
                this.isShow = false;
                this.path = info.file.response.path;
                }
                this.$message.success('file uploaded successfully');
            } else if (info.file.status === 'error') {
                this.$message.error('file upload failed');
            }
        },
        handleChange2(info){
            if (info.file.status === 'uploading') {
                this.loading = true;
                return;
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.=
                this.file_cover = info.file.response.path;;
                this.loading = false;
            }
        },
        handleChange3(info){
            if (info.file.status === 'uploading') {
                this.loading = true;
                return;
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.=
                this.logo = info.file.response.path;;
                this.loading = false;
            }
        },
        beforeUpload(file) {
            var that = this;
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const bigLt2M = file.size / 1024 / 1024 < 2;
            return new Promise((resolve, reject) => {
                if (!(isJPG || isJPEG || isPNG)) {
                that.$message.error('You can only upload JPG file!');
                reject(file);
                }else if (!bigLt2M) {
                that.$message.error('Image must smaller than 2MB!');
                reject(file);
                }else {
                resolve(file && this.checkImageWH2(file, 150, 85));
                }
            });
        },
        beforeUpload2(file) {
            var that = this;
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const bigLt2M = file.size / 1024 / 1024 < 2;
            return new Promise((resolve, reject) => {
                if (!(isJPG || isJPEG || isPNG)) {
                that.$message.error('You can only upload JPG file!');
                reject(file);
                }else if (!bigLt2M) {
                that.$message.error('Image must smaller than 2MB!');
                reject(file);
                }else {
                resolve(file && this.checkImageWH2(file, 300, 300));
                }
            });
        },
        checkImageWH2(file, width, height) {
        var that = this;
        return new Promise(function(resolve, reject) {
            let filereader = new FileReader();
            filereader.onload = e => {
            let src = e.target.result;
            const image = new Image();
            image.onload = function() {
                // 获取图片的宽高，并存放到file对象中
                console.log('file width :' + this.width);
                console.log('file height :' + this.height);
                file.width = this.width;
                file.height = this.height;
                resolve();
                if (this.width == width && this.height == height)
                {
                resolve();
                } else {
                that.$message.error('Please upload '+width+'px*'+height+'px image');
                reject();
                }
            };
            image.onerror = reject;
            image.src = src;
            };
            filereader.readAsDataURL(file);
        });
        },
    }
}
</script>
<style scoped>
.login{background-image: linear-gradient(to right,rgb(200,29,21),rgb(200,29,21));height: 100vh;width: 100vw;display:flex;justify-content: center;align-items:center;}
.login .login_center{padding:50px 20px; width: 300px;border-radius: 15px;background-color: #fff;}
.login .title{font-weight:600;text-align:center;line-height: 20px;padding-bottom:40px;font-size:16px;}
.login .input-item{ display: block;width: 100%;margin-bottom: 20px;border: none;padding: 10px;border-bottom: 2px solid rgb(128,125,125);font-size: 15px;outline: none;}
.login .wrapbtn{display:flex;}
.login .wrapbtn .loginbtn{width:100%; justify-content: center;padding: 5px;margin-top: 20px; background-image: linear-gradient(to right,#fbc2eb,#a6c1ee);color: #fff;}
.login .signup{text-align: right;margin-top: 20px;cursor: pointer;}

</style>